<template>
  <section>
    <v-overlay :value="isLoading" absolute opacity="0.7" z-index="1000">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="600px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title class="mb-0 pb-0">
          <span class="text-h5">Create a new team member</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="doSave">
            <v-container fluid>
              <v-text-field v-model="teamMember.firstName" :rules="inputs.rules.simplyRequired" label="First name" placeholder="Johnny" outlined></v-text-field>
              <v-text-field v-model="teamMember.lastName" :rules="inputs.rules.simplyRequired" label="Last name" placeholder="Appleseed" outlined></v-text-field>
              <v-text-field v-model="teamMember.email" :rules="inputs.rules.emailRequired" label="Email" placeholder="john.Appleseed@sea2seascuba.com" outlined></v-text-field>
              <v-text-field v-model="teamMember.title" :rules="inputs.rules.simplyRequired" label="Title" placeholder="Instructor" outlined></v-text-field>
              <image-upload-and-crop ref="image" @doSave="doSaveImageObject" :error-box="(isValidated !== null && teamMember.image === null)"></image-upload-and-crop>
              <span class="caption ml-1 red--text" v-if="(isValidated !== null && teamMember.image === null)">Image upload is Required.</span>
              <v-textarea class="mt-4" outlined v-model="teamMember.about" label="About" hint="Description of the Team Member" no-resize rows="6" :rules="inputs.rules.simplyRequired" :counter="500"></v-textarea>
              <v-row>
                <v-col>
                  <v-btn @click="doClose">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width></font-awesome-icon>
                    <span class="ml-2">Cancel</span>
                  </v-btn>
                </v-col>
                <v-col align="right">
                  <v-btn color="primary" type="submit">
                    <span class="mr-2">Create</span>
                    <font-awesome-icon :icon="['fas', 'plus']" fixed-width></font-awesome-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>

import ImageUploadAndCrop from '@/components/image/ImageUploadAndCrop.vue'

export default {
  name: 'addTeamMember',
  components: { ImageUploadAndCrop },
  data: () => ({
    isOpen: false,
    isLoading: false,
    isValidated: null,
    teamMember: {
      firstName: null,
      lastName: null,
      email: null,
      title: null,
      about: null,
      image: null,
      isPublic: false
    }
  }),
  methods: {
    doOpen () {
      this.isOpen = true
    },
    doSaveImageObject (imageUrl, coordinates) {
      this.teamMember.image = {
        url: imageUrl,
        coordinates: coordinates
      }
    },
    doSave () {
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated && this.teamMember.image !== null) {
        this.isLoading = true

        this.$services.teamMember.doCreate(this.teamMember.firstName, this.teamMember.lastName, this.teamMember.email, this.teamMember.title, this.teamMember.about, this.teamMember.image, this.teamMember.isPublic).then(teamMember => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doClose () {
      this.isOpen = false
      this.doInit()
      this.$emit('doLoad')
    },
    doInit () {
      this.$refs.image.doInit()
      this.$refs.form.resetValidation()
      this.isValidated = null
      this.teamMember = {
        firstName: null,
        lastName: null,
        email: null,
        title: null,
        about: null,
        image: null,
        isPublic: false
      }
    }
  }
}
</script>

<style scoped>

</style>
